.skills {
    padding: 5rem 0 5rem;
    min-height: 200px;
    background-color: #1f1f1f;
}

.skills h1 {
    color: white;
    padding-bottom: 2rem;
    text-align: center;
    font: 18px/24px "opensans-bold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: normal;
    font-stretch: normal;
}

.skills-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

}

.skills-logos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(248,245,244,.05);
    padding: 10px 10px;
    height: 120px;
    width: auto;
    margin: 5px 8px 0;
    border-radius: 8px;
}

.skills-logos-container p {
    margin-top: 0.5rem;
    font-family: "devicon";
    text-align: center;
    color: white;
}



.skills img {
    width: auto;
    height: 4rem;
}