.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 70vh;
  background-color: #1f1f1f;
  /* background-color: #e9d5a1; */
}

.header-links-container {
  position: absolute;
  margin-top: 30rem;

  width: 20rem;
  display: flex;
  justify-content: space-around;
}

.header-links-container a {
  color: #FDF4DC;
}

.github-icon,
.linkedin-icon,
.resume-btn {
  font-size: 3rem;
}

.resume-btn {
  background-color: #FDF4DC !important;
  color: #1f1f1f !important;
  padding-top: 0.65rem !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.resume-btn:hover {
  background-color: rgb(0, 85, 188) !important;
}

.header-laptop-icon {
  color: #FDF4DC;
  margin-bottom: 10rem;
  height: 15rem;
  width: auto;
}

.header-name {
  color: #FDF4DC;
  position: absolute;
  margin-top: 10rem;
  font-size: 3rem;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.header-job {
  color: #FDF4DC;
  position: absolute;
  margin-top: 16rem;
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
  font-weight: 1000;
}

.styles_typicalWrapper__1_Uvh::after {
  cursor: none !important;
  display: none;
}

@media only screen and (max-width: 395px) {
  .header-laptop-icon {
    height: 10rem;
    width: auto;
  }

  .header-name {
    font-size: 2.5rem;
    margin-top: 5rem;
  }

  .header-job {
    font-size: 1.5rem;
    margin-top: 10rem;
  }

  .header-links-container {
    margin-bottom: 10rem;
   
  }

  .github-icon,
  .linkedin-icon,
  .resume-btn {
    font-size: 2.5rem;
  }

  .resume-btn {
    padding-top: 0.4rem !important;
  }
}

@media only screen and (max-width: 320px) {
  .header-laptop-icon {
    height: 8rem;
    width: auto;
  }

  .header-name {
    font-size: 2rem;
    margin-top: 2rem;
  }

  .header-job {
    font-size: 1.25rem;
    margin-top: 6rem;
  }

  .header-links-container {
    margin-bottom: 15rem;
    width: 100vw;
  }

  .github-icon,
  .linkedin-icon,
  .resume-btn {
    font-size: 2.5rem;
  }

  .resume-btn {
    padding-top: 0.4rem !important;
  }
}

@media only screen and (max-height: 550px) {
  .header-laptop-icon {
    height: 8rem;
    width: auto;
  }

  .header-name {
    font-size: 2rem;
    margin-top: 2rem;
  }

  .header-job {
    font-size: 1.25rem;
    margin-top: 6rem;
  }

  .header-links-container {
    margin-bottom: 15rem;
    width: 18rem;
  }

  .github-icon,
  .linkedin-icon,
  .resume-btn {
    font-size: 2.5rem;
  }

  .resume-btn {
    padding-top: 0.4rem !important;
  }
}
