.projects-container {
    background-color: #FDF4DC;
    padding-bottom: 3rem;

}

.demo-btn {
  color: #FDF4DC !important;
  background-color: #1f1f1f !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.demo-btn:hover {
  background-color: rgb(0, 85, 188) !important;
}

.projects-row-1 {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding-bottom: 3rem;
}

.projects-row-2 {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}
.project-item {
  transition: transform .45s;
}
.project-item:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 12px rgb(0 0 0 / 30%);
}


.projects h2 {
  text-align: center;
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  margin-top: 1rem;


}

.projects a {
  color: #1f1f1f
}

.projects p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  text-align: center;
}

.projects-container h1 {
    text-align: center;
    font: 18px/24px "opensans-bold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: normal;
    font-stretch: normal;
  
    padding-top: 3rem;
    padding-bottom: 3rem;
}



.project-item-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 6px rgb(0 0 0 / 30%);
  height: 32.5rem;
  width: 32rem;
  margin: auto;
  }

.github-demo-row-1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    padding-bottom: 1rem;
  }
  

.github-1 {
    font-size: 2.5rem ;
  }


.project-item-1 img {
    height: auto; 
    width: 30rem;
  }

.project-item-2 {

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: white;
    box-shadow: 0 4px 6px rgb(0 0 0 / 30%);
    height: 32.5rem;
    width: 32rem;
    margin: auto;
      

  }

.project-item-2 img {
    height: auto; 
    width: 27rem;
  }

.project-item-3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: white;
    box-shadow: 0 4px 6px rgb(0 0 0 / 30%);
    height: 32.5rem;
    width: 32rem;
    margin: auto;
      
  }

.project-item-3 img {
    height: auto;
    width: 22rem;
  }

.project-item-4 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items:center;
    background-color: white;
    box-shadow: 0 4px 6px rgb(0 0 0 / 30%);
    height: 32.5rem;
    width: 32rem;
    margin: auto;
  }

  .project-item-4 img {
    margin-bottom: 5rem;
    height: 12rem;
    width: auto;
  }

@media only screen and (max-width: 1440px) {
  .projects-row-1 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    padding-bottom: 1rem;
  }
  
  .projects-row-2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
  }
}

@media only screen and (max-width: 1100px) {

  .project-item-1 {
    margin-bottom: 1rem;
  }

  .project-item-3 {
    margin-bottom: 1rem;
  }
  .projects-row-1 {
    width: 100%;
    display: flex;
    flex-direction: column;

  }
  
  .projects-row-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 550px) {
  .project-item-1 {
    width: 25rem;
    height: 30rem;
  }

  .project-item-1 img {
    width: 22rem;
    margin-bottom: 3rem;
  }

  .project-item-2 {
    width: 25rem;
    height: 31rem;
  }

  .project-item-2 img {
    width: 22rem;
    margin-bottom: 1.5rem;
  }

  .project-item-3 {
    width: 25rem;
    height: 31rem;
  }

  .project-item-4 {
    width: 25rem;
    height: 24rem;
  }

  .project-item-4 img {
    width: 22rem;
    height: auto;
  }
}

@media only screen and (max-width: 435px) {
  .project-item-1 {
    width: 20rem;
    height: 29.5rem;
  }

  .github-demo-row-1 {
    width: 100%;
    justify-content: space-around;
  }

  .project-item-1 img {
    width: 19.5rem;
    margin-bottom: 3rem;
  }

  .project-item-2 {
    width: 20rem;
    height: 30rem;
  }

  .project-item-2 img {
    width: 19rem;
    margin-bottom: 3rem;
  }

  .project-item-3 {
    width: 20rem;
    height: 31rem;
  }

  .project-item-3 img {
    width: 19.5rem;
    margin-bottom: 2rem;
  }

  .project-item-4 {
    width: 20rem;
    height: 26rem;
  }

  .project-item-4 img {
    width: 19.5rem;
    height: auto;
    margin-bottom: 5rem;
  }

}

@media only screen and (max-width: 350px) {

  .projects h2 {
    font-size: 18px;
  }

  .projects p {
    font-size: 12px;
  }

  .github-1 {
    font-size: 2rem;
  }

  .github-demo-row-1 {
    width: 100%;
    justify-content: space-around;
  }

  .project-item-1 {
    width: 17rem;
    height: 26rem;
  }

  .project-item-1 img {
    width: 16rem;
    margin-bottom: 4rem;
  }



  .project-item-2 {
    width: 17rem;
    height: 25rem;
  }

  .project-item-2 img {
    width: 16rem;
    margin-bottom: 1rem;
  }

  .project-item-3 {
    width: 17rem;
    height: 30rem;
  }

  .project-item-3 img {
    width: 16rem;
    margin-bottom: 2rem;
  }

  .project-item-4 {
    width: 17rem;
    height: 22rem;
  }

  .project-item-4 img {
    width: 16rem;
    height: auto;
    margin-bottom: 3rem;
  }

}